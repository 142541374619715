import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchObjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/objects', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/object/objects/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteObject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/object/objects/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addObject(ctx, objectData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/object/objects', { object: objectData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/role/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
