<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <!--template #aside>
        <b-avatar
          ref="previewEl"
          :src="objectData.avatar"
          :text="avatarText(objectData.fullName)"
          :variant="`light-${resolveObjectRoleVariant(objectData.role)}`"
          size="90px"
          rounded
        />
      </template-->
      <h4 class="mb-1">
        {{ objectData.name }}
      </h4>
      <!--div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div-->
    </b-media>

    <!-- Object Info: Input Fields -->
    <b-form
      @submit_.prevent="handleSubmit(onSubmit)"
    >
      <b-row>

        <!-- Field: Objectname -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('locations.location_name')"
            label-for="objectname"
          >
            <b-form-input
              id="objectname"
              v-model="objectData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('locations.company')"
            label-for="object-company"
          >
            <v-select
              v-model="objectData.companyId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="companyOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="object-company"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Contact -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('locations.address')"
            label-for="contact"
          >
            <b-form-input
              id="contact"
              v-model="objectData.address"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="$t('locations.telegram_id')"
            label-for="telegram_id"
          >
            <b-form-input
              id="telegram_id"
              v-model="objectData.telegramId"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -- >
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card-->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
      type_="submit"
      @click="onSubmit"
    >
      {{ $t('common.save_changes') }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block_="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      {{ $t('common.reset') }}
    </b-button>
  </div>
</template>

<script>
import {
  // BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BButton, BMedia, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import useObjectsList from '../objects-list/useObjectsList'

export default {
  components: {
    BButton,
    BMedia,
    // BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    vSelect,
  },
  props: {
    objectData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      resolveObjectRoleVariant,
      roleOptions,
      companyOptions,
    } = useObjectsList()

    const statusOptions = [
      // { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Object',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.objectData.avatar = base64
    })

    const ok = {
      email: {
        success: null,
        error: '',
      },
      objectname: {
        success: null,
        error: '',
      },
      password: {
        success: null,
        error: '',
      },
    }

    const resOk = ref({})
    resOk.value = ok

    const onSubmit = () => {
      // console.log(props.objectData)
      resOk.value = ok
      store.dispatch('app-object/addObject', props.objectData)
        .then(() => {
          router.push({ name: 'apps-objects-list' })
        })
        .catch(e => {
          // console.log(e)
          if (typeof e === 'object') {
            if (e.status === 400 && e.data && e.data.error) {
              Object.keys(e.data.error).forEach(err => {
                if (e.data.error[err]) {
                  resOk.value[err].success = true
                  resOk.value[err].error = e.data.error[err].join('; ')
                  // console.log(resOk)
                  // eslint-disable-next-line
                  alert(`${err}: ${e.data.error[err].join('; ')}`)
                }
              })
            }
          }
        })
    }

    return {
      resolveObjectRoleVariant,
      avatarText,
      roleOptions,
      companyOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      onSubmit,
      resOk,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
