import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import i18n from '@/libs/i18n'

export default function useObjectsList() {
  // Use toast
  const toast = useToast()

  const refObjectListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: i18n.t('locations.location'),
      formatter: title,
      sortable: true,
    },
    { key: 'address', label: i18n.t('locations.address'), sortable: true },
    {
      key: 'company_id',
      label: i18n.t('locations.company'),
      formatter: title,
      sortable: true,
    },
    { key: 'actions', label: i18n.t('common.actions') },
  ]
  const perPage = ref(10)
  const totalObjects = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refObjectListTable.value ? refObjectListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalObjects.value,
    }
  })

  const refetchData = () => {
    refObjectListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, companyFilter, statusFilter], () => {
    refetchData()
  })

  const fetchObjects = (ctx, callback) => {
    store
      .dispatch('app-object/fetchObjects', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        company: companyFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { objects, total } = response.data

        callback(objects)
        totalObjects.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching objects list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteObject = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: i18n.t('swal.sure'),
      html: `${i18n.t('locations.location')} <b>${item.name}</b> ${i18n.t('swal.deleted_will')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: i18n.t('swal.yes'),
      cancelButtonText: i18n.t('swal.no'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-object/deleteObject', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: i18n.t('swal.deleted'),
              text: `${i18n.t('locations.location')} ${item.name} ${i18n.t('swal.deleted_has')}.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: `${i18n.t('locations.location')} ${i18n.t('swal.delete_error')}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const roleOptions = ref([])
  store.dispatch('app-object/fetchRoles')
    .then(response => {
      // console.log('RESP', response.data)
      roleOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-object/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveObjectRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveObjectRoleIcon = role => {
    if (role === 'subscriber') return 'ObjectIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'ObjectIcon'
  }

  const resolveObjectRoleText = role => {
    try {
      return roleOptions.value.find(o => o.value === role).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveObjectStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveObjectCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchObjects,
    tableColumns,
    perPage,
    currentPage,
    totalObjects,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refObjectListTable,

    resolveObjectRoleVariant,
    resolveObjectRoleIcon,
    resolveObjectRoleText,
    resolveObjectStatusVariant,
    resolveObjectCompanyText,
    refetchData,

    // Extra Filters
    roleFilter,
    companyFilter,
    statusFilter,

    roleOptions,
    companyOptions,

    deleteObject,

  }
}
